<script setup lang="ts">
const props = defineProps<{ video: YouTubeVideo }>()

// TODO: responsiveness
const styles = computed(() => {
  if (!props.video.width) return { width: '50%' }

  switch (props.video.width) {
    case YouTubeWidth.Large:
      return { width: '100%' }
    case YouTubeWidth.Medium:
      return { width: '50%' }
    case YouTubeWidth.Small:
      return { width: '25%' }
  }
})
</script>

<template>
  <iframe
    v-if="props.video.url"
    :title="props.video.title"
    :style="styles"
    :class="['aspect-video']"
    :src="`https://www.youtube-nocookie.com/embed/${extractYouTubeVideoId(props.video.url)}`"
    frameborder="0"
    allowfullscreen
  />
</template>
