import { ContentBlockFormYouTube } from '#components'

import {
  ContentBlockFourCards,
  ContentBlockImage,
  ContentBlockImageTextButtons,
  ContentBlockMarkdown,
  ContentBlockViewDynamicCards,
  ContentBlockViewYouTube,
} from '#components'

// This composable returns 2 maps:
// - A map of the form components for each content block type.
// - A map of the view components for each content block type.
export default function useContentBlockMaps() {
  const contentBlockFormMap = {
    // Undefined means: use the legacy method
    [ContentBlockType.Markdown]: undefined,
    [ContentBlockType.Image]: undefined,
    [ContentBlockType.ImageTextButtons]: undefined,
    [ContentBlockType.FourCards]: undefined, // This one is disabled for Meedoen
    // This one is a mix of legacy vs new method.
    [ContentBlockType.DynamicCards]: undefined,
    // This one is for Meedoen specifically
    // [ContentBlockType.MeedoenBalies]: ContentBlockFormMeedoenBalies,
    [ContentBlockType.YouTube]: ContentBlockFormYouTube,
  }

  const contentBlockViewMap = {
    [ContentBlockType.Markdown]: ContentBlockMarkdown,
    [ContentBlockType.Image]: ContentBlockImage,
    [ContentBlockType.ImageTextButtons]: ContentBlockImageTextButtons,
    [ContentBlockType.FourCards]: ContentBlockFourCards,
    [ContentBlockType.DynamicCards]: ContentBlockViewDynamicCards,
    [ContentBlockType.YouTube]: ContentBlockViewYouTube,
  }

  return { contentBlockFormMap, contentBlockViewMap }
}
