<script setup lang="ts"></script>

<template>
  <div>
    <slot name="title" />
    <div class="gap-x-10 pb-10">
      <slot />
    </div>
  </div>
</template>
