<script setup lang="ts">
import { type ContentBlockType as ContentBlockTypeType } from '#imports'

const props = defineProps<{ contentBlockType: ContentBlockTypeType }>() // typeof ContentBlockType }
// const emit = defineEmits<{}>()

// const contentBlockType: typeof ContentBlockType = ContentBlockType.MeedoenBalies
// VCSode typescript complains that the composable doesn't exist, but I don't understand why.
// The composable exists.
// @ts-ignore
const { contentBlockFormMap } = useContentBlockMaps()
const formComponent = contentBlockFormMap[props.contentBlockType]
</script>

<template>
  <div>
    <component :is="formComponent" />
  </div>
</template>
