<script setup lang="ts">
// This doesn't work apparently `defineProps<DynamicCardsBlockPayload>()`
const { cards, isCarousel } = defineProps<{
  cards: DynamicCardPayload[]
  isCarousel: boolean
}>()

const cardCount = cards.length

// Determine grid column class based on number of valid cards
const gridColClass = computed(() => {
  switch (cardCount) {
    case 2:
      return 'col-span-3'
    case 3:
      return 'col-span-6 md:col-span-4'
    default:
      return 'col-span-3'
  }
})

// Carousel code
const showLeftButton = ref(false)
const showRightButton = ref(isCarousel)
const carousel = ref<HTMLElement | null>(null)
// Get a ref to the first card to determine its width for scrolling behaviors.
const cardElement = ref<HTMLElement | null>(null)
const setCardRef = (componentInstance: any) => {
  if (componentInstance && !cardElement.value) {
    cardElement.value = componentInstance.rootElement
  }
}

const scroll = (direction: 'left' | 'right') => {
  if (carousel.value && cardElement.value) {
    const cardWidth = cardElement.value.offsetWidth
    carousel.value.scrollLeft += direction === 'left' ? -cardWidth : cardWidth
  }
}

const threshold = 5 // You can adjust this value as needed

const checkScroll = () => {
  if (carousel.value) {
    const maxScrollLeft
      = carousel.value.scrollWidth - carousel.value.clientWidth
    showRightButton.value
      = carousel.value.scrollLeft < maxScrollLeft - threshold
    showLeftButton.value = carousel.value.scrollLeft > threshold
  }
}

onMounted(() => {
  if (isCarousel && carousel.value) {
    carousel.value.addEventListener('scroll', checkScroll)
  }
})
</script>

<template>
  <div
    ref="carousel"
    class="py-4"
    :class="{
      'hidden-scrollbar scroll flex w-full snap-x snap-mandatory snap-start gap-x-2 overflow-x-scroll scroll-smooth md:overflow-x-hidden':
        isCarousel,
      'grid grid-cols-3 gap-6 md:grid-cols-12': !isCarousel,
    }"
  >
    
    <div
      v-if="cardCount === 2 && !isCarousel"
      class="col-span-3 hidden md:block"
    />

    <ContentBlockViewPartialCard
      v-for="(card, index) in cards"
      :key="index"
      :ref="setCardRef"
      :card="card"
      :class="[
        gridColClass,
        isCarousel
          ? // On mobile, show 2 cards at a time, with a bit of spacing. On sm show 3. On md, show 4.4 cards at a time.
            'w-[calc(100%/2-1rem)] flex-none sm:w-[calc(100%/3-1rem)] md:w-[calc(100%/4.4-1rem)]'
          : '',
      ]"
    />

    
    <div
      v-if="cardCount === 2 && !isCarousel"
      class="col-span-3 hidden md:block"
    />

    
    <Transition
      enter-active-class="transition-opacity duration-200 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-200 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-if="showLeftButton" class="carousel-fade-left" />
    </Transition>

    
    <div
      v-if="isCarousel"
      class="carousel-button absolute left-0 top-0 z-10 flex h-full w-0 flex-col justify-center"
      :class="{ hidden: !showLeftButton }"
    >
      <BaseButtonIcon
        shape="full"
        color="muted"
        @click="scroll('left')"
      >
        <Icon name="ph:arrow-left" />
      </BaseButtonIcon>
    </div>

    
    <Transition
      enter-active-class="transition-opacity duration-200 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-200 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-if="showRightButton" class="carousel-fade-right" />
    </Transition>

    
    <div
      v-if="isCarousel"
      class="carousel-button absolute right-0 top-0 flex h-full w-0 flex-col justify-center"
      :class="{ hidden: !showRightButton }"
    >
      <BaseButtonIcon
        shape="full"
        color="muted"
        class="-translate-x-full"
        @click="scroll('right')"
      >
        <Icon name="ph:arrow-right" />
      </BaseButtonIcon>
    </div>
  </div>
</template>

<style>
.hidden-scrollbar {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

/* Adjust the last card's margin to prevent extra space at the end */
.carousel-container > :last-child {
  @apply mr-0;
}

.carousel-fade-right {
  @apply pointer-events-none absolute bottom-0 right-0 top-0 w-12 bg-gradient-to-r from-transparent to-white content-[''];
}

.carousel-fade-left {
  @apply pointer-events-none absolute bottom-0 left-0 top-0 w-12 bg-gradient-to-l from-transparent to-white content-[''];
}

.carousel-button {
  /* Prevent zooming in on mobile when tapping the buttons quickly in succession */
  touch-action: manipulation;
}
</style>
