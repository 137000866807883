<script setup lang="ts">
const pageHasSideColumn = inject<boolean>('pageHasSideColumn')

// For meedoen, we never want to center.
const isMeedoen = useRuntimeConfig().public.isMeedoen
</script>

<template>
  <div
    class="flex"
    :class="{ 'justify-center': !pageHasSideColumn && !isMeedoen }"
  >
    <div class="w-full max-w-2xl">
      <slot />
    </div>
  </div>
</template>
