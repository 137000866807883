<script setup lang="ts">
const { fields, push, remove }
  = useFieldArray<Partial<DynamicCardPayload>>('cards')

const { t } = useI18n({ useScope: 'local' })

const { value: isCarousel } = useField<boolean>('isCarousel')
</script>

<template>
  <div class="flex flex-col gap-y-4 pt-4">
    <BaseParagraph class="mt-0">
      {{ t('explanation') }}
    </BaseParagraph>

    <BaseSwitchThin
      v-model="isCarousel"
      label="Carousel"
      sublabel="Weergeven als carousel met scroll- en swipefunctionaliteit."
    />

    <BaseMessage v-if="fields.length === 0" type="info">
      {{
        t('noCardsAdded')
      }}
    </BaseMessage>

    <div
      v-for="(card, index) in fields"
      :key="index"
      class="border-primary-500 relative flex flex-col gap-y-3 rounded-xl border p-4"
    >
      <div class="flex items-center justify-between">
        <BaseHeading
          tag="h4"
          size="lg"
          weight="medium"
        >
          {{ t('card') }} {{ index + 1 }}
        </BaseHeading>

        <BaseButtonIcon
          color="danger"
          shape="curved"
          muted
          class="scale-75"
          @click="remove(index)"
        >
          <Icon name="ph:trash" class="size-5" />
        </BaseButtonIcon>
      </div>

      <FormListField
        :name="`cards[${index}].mediaType`"
        :items="Object.values(DynamicCardMediaType)"
        :label="t('mediaTypeLabel')"
        :label-factory="(option) => t(`mediaTypeOptions.${option}`)"
      />

      <FormField
        v-if="card.value.mediaType == 'youtube'"
        :name="`cards[${index}].youtubeUrl`"
        label="YouTube URL"
        keep-value
      />
      
      <FormImageField
        v-if="card.value.mediaType == 'image'"
        :name="`cards[${index}].image`"
        label="Afbeelding"
        keep-value
      />

      <FormField :name="`cards[${index}].title`" label="Titel" />
      <FormField :name="`cards[${index}].body`" label="Tekst" />
      <FormField :name="`cards[${index}].buttonText`" label="Button tekst" />
      <FormField :name="`cards[${index}].buttonUrl`" label="Button URL" />
    </div>

    <BaseButton
      color="primary"
      @click="push({ title: undefined, body: undefined, mediaType: undefined })"
    >
      {{ t('addCard') }}
    </BaseButton>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "explanation": "This is a section where you can add as many cards as you want.",
    "card": "Card",
    "noCardsAdded": "You haven't added any cards yet.",
    "addCard": "Add a card",
    "mediaTypeLabel": "Choose media type",
    "mediaTypeOptions": {
      "no-media": "No video or image",
      "image": "Image",
      "youtube": "YouTube video"
    }
  },
  "nl": {
    "explanation": "Dit is een sectie waarin je een rij horizontale kaarten (blokjes) kunt toevoegen.",
    "card": "Kaart",
    "noCardsAdded": "Je hebt nog geen kaarten toegevoegd.",
    "addCard": "Voeg een kaart toe",
    "mediaTypeLabel": "Kies media type",
    "mediaTypeOptions": {
      "no-media": "Geen video of afbeelding",
      "image": "Afbeelding",
      "youtube": "YouTube video"
    }
  }
}
</i18n>
