<script setup lang="ts">
import { ContentDefaultLayout, ContentSideColumnLayout } from '#components'
import { type PageLayout as PageLayoutType } from '#imports'

const { slug } = defineProps<{ slug: string }>()

const { t } = useI18n({ useScope: 'local' })

const { page, contentBlocks, refresh, error } = await fetchPageBySlug(slug)

const { isCenterAdmin, roles } = useCurrentOrganizationRoles()

const isPageManager = computed(() =>
  (roles.value || []).find(
    role =>
      role.name == 'page_manager'
      && role.resourceType == 'Cms::Page'
      && role.resourceId == page.value.id,
  ),
)
const canEdit = computed(() => !!(isCenterAdmin.value || isPageManager.value))

// TODO: throw 404
if (error.value) {
  console.error(`Throwing 404 because failed to fetch page`, error.value)
  throw { statusCode: 404, message: 'Page not found' }
}

const nextPosition = computed(() => {
  if (!contentBlocks.value) return 0
  return (
    (contentBlocks.value[contentBlocks.value.length - 1]?.position || -1) + 1
  )
})

const addBlockModelOpen = ref(false)

watch(addBlockModelOpen, (open) => {
  if (!open) {
    refresh()
  }
})

const editPageModalOpen = ref(false)
const lastClosed = ref(new Date())

const onPageModalClose = () => {
  lastClosed.value = new Date()
  editPageModalOpen.value = false
  refresh()
}

// When the first block is a markdown block (which is centered), we want to center the title too.
// otherwise, we keep the title left aligned.
const isTitleCentered = computed(() => {
  return !!(contentBlocks.value?.length > 0
    && contentBlocks.value[0].blockType == ContentBlockType.Markdown)
})

const pageLayoutComponent = {
  [PageLayout.Default]: ContentDefaultLayout,
  [PageLayout.SideColumn]: ContentSideColumnLayout,
}

const pageLayout = computed(() => {
  const currentLayout = page.value?.layout as PageLayoutType
  return pageLayoutComponent[currentLayout || PageLayout.Default]
})

const hasSideColumn = computed(
  () => pageLayout.value === ContentSideColumnLayout,
)

provide('pageHasSideColumn', hasSideColumn)
</script>

<template>
  <div v-if="page">
    <component :is="pageLayout" class="pb-8">
      <template #title>
        <ContentPageTitle
          :page="page"
          :centered="isTitleCentered"
        />
      </template>

      <ContentBlockRenderer
        v-for="block in contentBlocks"
        :key="block.id"
        :can-edit="canEdit"
        :content-block="block"
        class=""
        @moved="refresh"
      />
    </component>

    <template v-if="canEdit">
      
      <ContentContainer>
        <div
          class="border-1 mb-4 flex flex-col justify-center gap-2 rounded border bg-slate-200 p-4 md:flex-row"
        >
          <OverviewAddButton @click="addBlockModelOpen = true">
            {{
              t('addBlock')
            }}
          </OverviewAddButton>

          <BaseButton @click="editPageModalOpen = true">
            <Icon name="ph:pencil" class="mr-1 size-4 cursor-pointer" />
            {{ t('editSettings') }}
          </BaseButton>
        </div>
      </ContentContainer>

      <ContentBlockFormModal
        :key="nextPosition"
        :open="addBlockModelOpen"
        :page-id="page.id"
        :position="nextPosition"
        @close="addBlockModelOpen = false"
      />

      <CmsPageFormModal
        :key="page.updatedAt + lastClosed"
        :open="editPageModalOpen"
        :initial-page="page"
        @close="onPageModalClose"
      />
    </template>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "addBlock": "Add block",
    "editSettings": "Edit settings"
  },
  "nl": {
    "addBlock": "Blok toevoegen",
    "editSettings": "Instellingen bewerken"
  }
}
</i18n>
