// Not sure why this doesn't auto-import
// import type { ContentBlock, ContentBlockPayload } from '@/schemas/contentBlocks'

export async function createContentBlock(attributes: ContentBlockPayload) {
  const { data } = await $api<JSONAPIResponse<ContentBlock>>(
    '/api/v1/cms/content_blocks',
    {
      method: 'POST',
      body: {
        data: {
          type: 'content_block',
          attributes: attributes,
        },
      },
    },
  )

  return data.attributes
}

export async function updateContentBlock(
  id: id,
  attributes: ContentBlockPayload,
) {
  const { data } = await $api<JSONAPIResponse<ContentBlock>>(
    `/api/v1/cms/content_blocks/${id}`,
    {
      method: 'PUT',
      body: {
        data: {
          type: 'content_block',
          attributes: attributes,
        },
      },
    },
  )

  return data.attributes
}

export async function moveContentBlockDown(id: id) {
  const { data } = await $api<JSONAPIResponse<ContentBlock>>(
    `/api/v1/cms/content_blocks/${id}/move_down`,
    {
      method: 'PUT',
    },
  )

  return data.attributes
}

export async function deleteContentBlock(id: id) {
  await $api<JSONAPIResponse<ContentBlock>>(
    `/api/v1/cms/content_blocks/${id}`,
    {
      method: 'DELETE',
    },
  )
}
