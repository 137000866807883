<script setup lang="ts">
const { contentBlock, canEdit } = defineProps<{
  contentBlock: ContentBlock
  canEdit: boolean
}>()

const emits = defineEmits(['moved', 'updated'])

const { contentBlockViewMap: componentMap } = useContentBlockMaps()

// Note: props might be newer due to move/refresh!
const updatedVersion = ref<ContentBlock | undefined>()
const lastVersion = computed<ContentBlock>(() => {
  if (
    updatedVersion.value
    && updatedVersion.value.updatedAt > contentBlock.updatedAt
  ) {
    return updatedVersion.value
  }
  return contentBlock
})

const cacheKey = computed(() => {
  return `${lastVersion.value.id}-${lastVersion.value.updatedAt}`
})

const moved = (updatedBlock: ContentBlock) => {
  emits('moved', updatedBlock)
}

const updated = (updatedBlock: ContentBlock) => {
  updatedVersion.value = updatedBlock
}

const componentToRender
  = componentMap[contentBlock.blockType as keyof typeof componentMap]

if (!componentToRender) {
  throw new Error(`No component found for block type ${contentBlock.blockType}`)
}

const isDeleted = ref(false)
</script>

<template>
  
  <div
    v-if="!isDeleted"
    :data-name="contentBlock.blockType"
    :data-block-id="contentBlock.id"
    class="relative min-h-16"
    :class="{
      group: canEdit,
    }"
  >
    <ContentBlockHoverButtons
      v-if="canEdit"
      class="group-hover:block md:hidden"
      :content-block="contentBlock"
      @moved="moved"
      @updated="updated"
      @deleted="isDeleted = true"
    />

    <ContentBlockDivider v-if="canEdit" class="hidden group-hover:block" />

    <ContentContainer>
      <component
        :is="componentToRender"
        :key="cacheKey + '-view'"
        class="min-h-16"
        v-bind="lastVersion.data"
      />
    </ContentContainer>

    <ContentBlockDivider v-if="canEdit" class="hidden group-hover:block" />
  </div>
</template>
