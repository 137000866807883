<script setup lang="ts">
const { card } = defineProps<{ card: DynamicCardPayload }>()
const rootElement = ref(null)
defineExpose({ rootElement })

// const emit = defineEmits<{}>()
// const { t } = useI18n({ useScope: 'local' })
// const { t: t } = useI18n({ useScope: 'global' })

const imageUrl = computed(() => {
  if (card.image?.hasOwnProperty('fileUrl')) {
    return (card.image as ContentFile).fileUrl
  }
  return null
})
</script>

<template>
  <BaseCard class="overflow-hidden" shape="curved">
    <div ref="rootElement" class="flex h-full flex-col">
      
      <div
        v-if="imageUrl"
        class="relative mx-auto aspect-video w-full overflow-hidden pt-[56.25%]"
      >
        <img
          class="absolute left-0 top-0 aspect-video size-full object-cover"
          :src="imageUrl"
          :alt="card.title"
        >
      </div>
      <div
        v-if="card.youtubeUrl"
        class="relative mx-auto w-full overflow-hidden"
      >
        <iframe
          :title="card.title"
          :class="['aspect-video w-full']"
          :src="`https://www.youtube-nocookie.com/embed/${extractYouTubeVideoId(card.youtubeUrl)}`"
          frameborder="0"
          allowfullscreen
        />
      </div>

      <div class="flex grow flex-col justify-between gap-y-3 px-5 pb-6 pt-4">
        <BaseHeading
          size="md"
          as="h4"
          class="mt-1.5"
        >
          {{ card.title }}
        </BaseHeading>

        <TextParagraph
          v-if="card.body"
          size="sm"
          color="light"
        >
          {{ card.body }}
        </TextParagraph>

        
        <div class="mt-auto">
          <BaseButton
            v-if="card.buttonText"
            color="primary"
            class="mt-2 w-full md:w-auto"
            :href="card.buttonUrl"
          >
            {{ card.buttonText }}
          </BaseButton>
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<i18n lang="json">
{
  "en": {
    "": ""
  },
  "nl": {
    "": ""
  }
}
</i18n>
