<script setup lang="ts">
// const { fullWidth } = defineProps<{
//   fullWidth?: boolean
// }>()

// Not sure if this is really necessary for the ContentContainer.
const fullWidth = false

const applyPadding = inject<boolean>('addContentContainerPadding', false)
</script>

<template>
  <section
    class="relative mx-auto w-full"
    :class="{ 'max-w-7xl': !fullWidth, 'md:px-4': applyPadding }"
  >
    <slot />
  </section>
</template>
