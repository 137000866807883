<script setup lang="ts">
const { contentBlock } = defineProps<{ contentBlock: ContentBlock }>()

const emits = defineEmits(['updated', 'deleted', 'moved'])

const editModelOpen = ref(false)
const lastBlockVersion = ref<ContentBlock>(contentBlock)

// Fix caching issue with modal
// This is very uggly. There must be a much cleaner way to make sure the modal
// is not initialized with the empty form data after closing it.
const lastOpen = ref<string>(contentBlock.updatedAt)

const cacheKey = computed(() => {
  return `${lastBlockVersion.value.id}-${lastOpen.value}`
})

const closeModal = () => {
  editModelOpen.value = false
  // Let animation finish before re-rendering
  setTimeout(() => {
    lastOpen.value = new Date().toISOString()
  }, 100)
}

const onDeleteClick = async () => {
  if (confirm('Weet je zeker dat je dit blok wilt verwijderen?')) {
    // TODO: error handling
    await deleteContentBlock(lastBlockVersion.value.id)
    emits('deleted')
  }
}

const onMoveDownClick = async () => {
  // TODO: error handling
  await moveContentBlockDown(lastBlockVersion.value.id)
  emits('moved', lastBlockVersion.value)
}

const onSave = (updatedBlock: ContentBlock) => {
  lastBlockVersion.value = updatedBlock
  emits('updated', updatedBlock)
}

const baseButtonClasses
  = 'bg-white hover:bg-muted-200 rounded-xl p-3 border mr-2'
const defaultButtonClasses = `text-primary-500 border-primary-500 hover:border-primary-800 ${baseButtonClasses}`
const deleteButtonClasses = `text-red-500 border-red-500 hover:border-red-800  ${baseButtonClasses}`
</script>

<template>
  <div class="absolute right-0 top-2 z-30 flex">
    <button :class="defaultButtonClasses" @click="editModelOpen = true">
      <Icon name="ph:pencil" class="size-4" />
    </button>

    <button :class="defaultButtonClasses" @click="onMoveDownClick">
      <Icon name="ph:arrow-down" class="size-4" />
    </button>

    <button :class="deleteButtonClasses" @click="onDeleteClick">
      <Icon name="ph:trash" class="size-4" />
    </button>

    <ContentBlockFormModal
      :key="cacheKey + '-edit'"
      :open="editModelOpen"
      :initial-block="lastBlockVersion"
      :position="lastBlockVersion.position"
      :page-id="lastBlockVersion.pageId"
      @close="closeModal()"
      @saved="onSave"
    />
  </div>
</template>
