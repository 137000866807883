import {
  array,
  boolean,
  mixed,
  number,
  object,
  string,
  type InferType,
} from 'yup'

// Block content schemas
export const markdownSchema = object({
  body: string().required(),
})

export const imageSchema = object({
  // TODO: image size validation, like in vacancy schema.
  // TODO: file size, make a reusable file schema that can be used for the vacancy form as well
  image: mixed<File | ContentFile>().required(),
  altText: string().required(),
  caption: string().optional(),
})

export enum ImageWithButtonsBlockStyles {
  HeroLarge = 'hero_large',
  HeroMedium = 'hero_medium',
  Callout = 'callout',
  CalloutColored = 'callout_colored',
}

export const imageTextButtonsSchema = object({
  styling: string()
    .oneOf(Object.values(ImageWithButtonsBlockStyles))
    .required(),
  image: mixed<File | ContentFile>().optional(),
  isSwapped: boolean().optional(),
  headingText: string().required(),
  bodyText: string().required(),
  primaryButtonText: string().optional(),
  primaryButtonUrl: string().optional(),
  secondaryButtonText: string().optional(),
  secondaryButtonUrl: string().optional(),
})

export enum DynamicCardMediaType {
  NoMedia = 'no-media',
  Image = 'image',
  YouTube = 'youtube',
}

export const dynamicCardSchema = object({
  title: string().required(),
  mediaType: string().oneOf(Object.values(DynamicCardMediaType)).required(),
  image: mixed<File | ContentFile>().optional(),
  youtubeUrl: string()
    .optional()
    .matches(
      /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/,
      'Enter a valid YouTube URL',
    ),
  body: string().required(),
  buttonText: string().optional(),
  buttonUrl: string().optional(),
})

export const dynamicCardsSchema = object({
  cards: array(dynamicCardSchema).min(1).required(),
  isCarousel: boolean().default(false),
})

export enum YouTubeWidth {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

// Schema for individual YouTube video
export const youtubeVideoSchema = object({
  url: string()
    .required('YouTube URL is required')
    .matches(
      /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/,
      'Enter a valid YouTube URL',
    ),
  title: string().optional(),
  description: string().optional(),
  width: string()
    .optional()
    .oneOf(Object.values(YouTubeWidth), 'Invalid width option')
    .required('Width is required'),
})

// Schema for the entire component
export const youtubeBlockSchema = object({
  youtubeVideos: array()
    .of(youtubeVideoSchema)
    .min(1, 'At least one video is required')
    .required(),
})

export const fourCardsSchema = object({
  mainActionLabel: string().optional(),
  mainActionPath: string().optional(),
  title1: string().optional(),
  title2: string().optional(),
  title3: string().optional(),
  title4: string().optional(),
  image1: mixed<File | ContentFile>().optional(),
  image2: mixed<File | ContentFile>().optional(),
  image3: mixed<File | ContentFile>().optional(),
  image4: mixed<File | ContentFile>().optional(),
  body1: string().optional(),
  body2: string().optional(),
  body3: string().optional(),
  body4: string().optional(),
  buttonText1: string().optional(),
  buttonText2: string().optional(),
  buttonText3: string().optional(),
  buttonText4: string().optional(),
  buttonUrl1: string().optional(),
  buttonUrl2: string().optional(),
  buttonUrl3: string().optional(),
  buttonUrl4: string().optional(),
})

// This is only for legacy content blocks.
// Don't include new content blocks
export const blockSchemaMap = {
  [ContentBlockType.Markdown]: markdownSchema,
  [ContentBlockType.Image]: imageSchema,
  [ContentBlockType.ImageTextButtons]: imageTextButtonsSchema,
  [ContentBlockType.FourCards]: fourCardsSchema,
  [ContentBlockType.DynamicCards]: dynamicCardsSchema,
}

export const contentBlockSchema = object({
  pageId: number().required(),
  blockType: string().required().oneOf(Object.values(ContentBlockType)),
  data: object(),
  // TODO: fix or remove? handle different schemas for different types
  // I don't think this is actually being used?
  // .when('blockType', {
  //   is: (typeValue: ContentBlockType) => {
  //     console.log(`is typeValue`, typeValue)
  //     return typeValue == ContentBlockType.Markdown
  //   },
  //   then: (schema) => {
  //     console.log(`then schema:`, markdownSchema)
  //     return markdownSchema
  //   },
  // })
  // .when('blockType', {
  //   is: (typeValue: typeofContentBlockType) => {
  //     console.log(`is typeValue`, typeValue)
  //     return typeValue == ContentBlockType.ImageTextButtons
  //   },
  //   then: (schema) => {
  //     console.log(`then schema:`, imageTextButtonsSchema)
  //     return imageTextButtonsSchema
  //   },
  // })
  // .required(),
  position: number().required(),
})

export interface DynamicCardPayload
  extends InferType<typeof dynamicCardSchema> {}
export interface DynamicCardsBlockPayload
  extends InferType<typeof dynamicCardsSchema> {}

export interface YouTubeVideo extends InferType<typeof youtubeVideoSchema> {}
export interface YouTubeBlockPayload
  extends InferType<typeof youtubeBlockSchema> {}

export interface ContentBlock extends InferType<typeof contentBlockSchema> {
  id: string
  createdAt: string
  updatedAt: string
}

// We could also do `InferType<typeof contentBlockSchema>` here I guess.
export interface ContentBlockPayload
  extends Omit<ContentBlock, 'id' | 'createdAt' | 'updatedAt'> {}
